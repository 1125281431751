import { Link } from 'gatsby'
import React from 'react'
import { FlagImage_WpPost_Fragment } from '../../../../types/graphql-types'
import { useSiteMetaData } from '../../../hooks/useSiteMetaData'
import { joinSpace } from '../../../libs/utils'
import * as styles from './card.module.styl'

interface IfProps {
  isFirst: boolean
  link: string
  title: string
  expect: string
  date: string
  dateGmt: string
  image?: FlagImage_WpPost_Fragment['featuredImage']
}

const Card: React.FC<IfProps> = ({
  isFirst,
  link,
  title,
  expect,
  date,
  dateGmt,
  image
}) => {
  const { defaultImage } = useSiteMetaData()

  const bgImage = (
    img?: FlagImage_WpPost_Fragment['featuredImage']
  ): string => {
    if (img == null) return defaultImage
    if (img.node == null) return defaultImage
    if (img.node.localFile == null) return defaultImage

    return img.node.localFile.publicURL || defaultImage

    // TODO: 画像生成がされるまでデータが存在しないので後で再考
    // if (img.node.localFile.childImageSharp == null) return defaultImage
    // if (img.node.localFile.childImageSharp.resize == null) return defaultImage
    // return img.node.localFile.childImageSharp.resize.src || defaultImage
  }

  return (
    <Link to={link} className={joinSpace([styles.card])} data-full={isFirst}>
      <div style={{ height: '100%' }}>
        <div className={joinSpace([styles.cardContainer])}>
          <div
            className={joinSpace([styles.cardThumb])}
            style={{
              backgroundImage: `url(${bgImage(image)})`,
              backgroundSize: 'cover'
            }}
          />
          <div className={joinSpace([styles.cardInformation])}>
            <div
              className={joinSpace([
                'typography-subhead',
                'fw-bold',
                styles.cardTitle
              ])}
            >
              {title}
            </div>
            <div
              className={joinSpace(['pt-4', styles.cardExpect])}
              dangerouslySetInnerHTML={{ __html: expect }}
            />
          </div>
          <time dateTime={dateGmt} className={joinSpace([styles.cardTime])}>
            {date}
          </time>
        </div>
      </div>
    </Link>
  )
}

export default Card
