import React from 'react'
import { IfAwesomePagination } from '../../../../types/utils'
import { joinSpace } from '../../../libs/utils'
import LinkButtonPager from '../../atoms/buttons/LinkButtonPager'
import * as styles from './pager.module.styl'

interface IfProps {
  items: IfAwesomePagination
  prefix: string
  firstPagePrefix?: string
}

const Pager: React.FC<IfProps> = ({ items, prefix, firstPagePrefix }) => {
  const pagination = [...Array(items.numberOfPages).keys()].map((i) => ++i)
  const { previousPagePath, nextPagePath, humanPageNumber } = items

  const pagePath = (page: number): string | undefined => {
    if (page === humanPageNumber) return

    // 1ページ目ではない
    if (page !== 1) return `${prefix}/${page}`

    // 1ページ目の場合
    return firstPagePrefix || prefix
  }

  return (
    <ul className={styles.pager}>
      {previousPagePath !== '' && (
        <li className={styles.pagerItem}>
          <LinkButtonPager name="前へ" target={previousPagePath} />
        </li>
      )}{' '}
      {pagination.map((page) => {
        return (
          <li
            key={page}
            className={joinSpace([styles.pagerItem, styles.pagerItemNumber])}
          >
            <LinkButtonPager name={page.toString(10)} target={pagePath(page)} />
          </li>
        )
      })}{' '}
      {items.nextPagePath !== '' && (
        <li className={styles.pagerItem}>
          <LinkButtonPager name="次へ" target={nextPagePath} />
        </li>
      )}
    </ul>
  )
}

export default Pager
