import React from 'react'
import { BlogPostItemsQuery } from '../../../../types/graphql-types'
import { joinSpace } from '../../../libs/utils'
import Ad from '../../atoms/ad/Ad'
import Card from '../../molecules/card/Card'

interface IfProps {
  items: BlogPostItemsQuery['allWpPost']
}

const ItemList: React.FC<IfProps> = ({ items }) => {
  return (
    <div className="apps-items">
      {items.edges.map(({ node }, index) => {
        return (
          <div
            key={node.id}
            className={joinSpace(['apps-items-item'])}
            data-item={index === 0 ? 'full' : null}
          >
            <div style={{ height: index === 0 ? 'auto' : '100%' }}>
              <Card
                isFirst={index === 0}
                link={node.uri as string}
                title={node.title as string}
                expect={node.excerpt as string}
                date={node.date}
                dateGmt={node.dateGmt}
                image={node.featuredImage}
              />
            </div>

            {index === 0 && (
              <div className={joinSpace(['pt-10', 'pb-4'])}>
                <Ad
                  adSlot="6017002935"
                  adFormat="auto"
                  adResponsive="true"
                  adStyle={{ display: 'block' }}
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ItemList
