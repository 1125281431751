import { graphql } from 'gatsby'
import React from 'react'
import { BlogCategoryItemsQuery } from '../../types/graphql-types'
import { IfAwesomePagination } from '../../types/utils'
import { useSiteMetaData } from '../hooks/useSiteMetaData'
import StructuredData from '../const/structuredData'
import DefaultLayout from '../layouts/DefaultLayout'
import HeadMeta from '../components/organisms/HeadMeta'
import TagHeadline from '../components/atoms/tag-headline/TagHeadline'
import ItemList from '../components/organisms/item-list/ItemList'
import Pager from '../components/molecules/pager/Pager'
import { archiveTitle, joinSpace } from '../libs/utils'

interface IfProps {
  data: BlogCategoryItemsQuery
  pageContext: IfAwesomePagination
}

const BlogCategoryPage: React.FC<IfProps> = ({ data, pageContext }) => {
  const {
    name: categoryName,
    uri: categoryUri,
    link: categoryLink,
    description: categoryDescription
  } = data.wpCategory as {
    name: string
    uri: string
    link: string
    description?: string
  }

  // StructuredData
  const sd$ = new StructuredData(useSiteMetaData())

  return (
    <DefaultLayout>
      {' '}
      <HeadMeta
        ogType="website"
        title={archiveTitle(categoryName, pageContext.humanPageNumber)}
        description={categoryDescription}
        structuredDataBreadcrumbs={sd$.breadcrumbs([
          { id: categoryLink, name: categoryName }
        ])}
        structuredDataArchive={sd$.archive(categoryLink, categoryName)}
      />{' '}
      <TagHeadline
        tag="h2"
        copy={categoryName}
        classes={['typography-subhead']}
      />{' '}
      <ItemList items={data.allWpPost} />{' '}
      {pageContext.numberOfPages > 1 && (
        <div className={joinSpace(['pt-6', 'pb-6'])}>
          <Pager items={pageContext} prefix={categoryUri} />
        </div>
      )}
    </DefaultLayout>
  )
}

export default BlogCategoryPage

export const query = graphql`
  query BlogCategoryItems($fieldValue: String!, $skip: Int!, $limit: Int!) {
    wpCategory(uri: { eq: $fieldValue }) {
      name
      description
      uri
      link
    }
    allWpPost(
      skip: $skip
      limit: $limit
      filter: {
        status: { eq: "publish" }
        categories: { nodes: { elemMatch: { uri: { eq: $fieldValue } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...flagPostDefault
          excerpt
        }
      }
    }
  }
`
