import React from 'react'
import '../css/global.styl'
import Footer from '../components/organisms/footer/Footer'
import HeaderDefault from '../components/organisms/header/HeaderDefault'
import { joinSpace } from '../libs/utils'

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div>
      <HeaderDefault />
      <div className={joinSpace(['apps', 'apps-main'])}>
        <div className={joinSpace(['apps-container'])}>{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default DefaultLayout
