import { Link } from 'gatsby'
import React from 'react'
import { useSiteMetaData } from '../../../hooks/useSiteMetaData'
import * as styles from './header-default.module.styl'

const HeaderDefault: React.FC = () => {
  const { defaultTitle } = useSiteMetaData()
  return (
    <header className={styles.header}>
      <h1 className={styles.headerHeadlineContainer}>
        <Link to="/" className={styles.headerHeadline}>
          {defaultTitle}
        </Link>
      </h1>
      <p className={styles.headerDescription}>
        Technology blog from Web Engineer
      </p>
    </header>
  )
}

export default HeaderDefault
