import React from 'react'
import { joinSpace } from '../../../libs/utils'
import * as styles from './tag-headline.module.styl'

interface IfProps {
  copy: string
  tag?: string
  classes?: string[]
}

const TagHeadline: React.FC<IfProps> = ({ tag, copy, classes }) => {
  const Tag = tag
  const classNames = [...(classes || [])]
  return (
    // @ts-ignore
    <Tag className={joinSpace(classNames)}>
      {' '}
      <span className={joinSpace([styles.tagHeadlineCopy])}>
        {copy}
      </span>の一覧{' '}
    </Tag>
  )
}

TagHeadline.defaultProps = {
  tag: 'h2'
}

export default TagHeadline
