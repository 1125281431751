import { Link } from 'gatsby'
import React from 'react'
import * as styles from './link-button-pager.module.styl'

interface IfProps {
  target?: string
  name: string
}

const LinkButtonPager: React.FC<IfProps> = ({ target, name }) => {
  if (target) {
    return (
      <Link to={target} className={styles.linkButton}>
        {name}
      </Link>
    )
  }

  return (
    <span className={styles.linkButton} data-disabled="true">
      {name}
    </span>
  )
}

export default LinkButtonPager
