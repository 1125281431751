import React, { useEffect } from 'react'

interface IfProps {
  adSlot: string
  adFormat: string
  adResponsive: string
  adStyle?: React.CSSProperties
}

// eslint-disable-next-line no-var
declare var adsbygoogle: any

const Ad: React.FC<IfProps> = ({ adSlot, adFormat, adResponsive, adStyle }) => {
  useEffect(() => {
    try {
      ;(adsbygoogle = window.adsbygoogle || []).push({})
    } catch (e) {
      console.error(e)
    }
  })

  return (
    <ins
      className="adsbygoogle"
      data-ad-client="ca-pub-0251375114144720"
      data-ad-slot={adSlot}
      data-ad-format={adFormat}
      data-full-width-responsive={adResponsive}
      style={{ ...adStyle, width: '100%' }}
    />
  )
}

export default Ad
